/**
 * configuration applied when BROCOLI_API_MODE = staging
 */
module.exports = {
  useShell: [
    'bb-default',
    {
      mode: 'universes',
      universes: [
        {
          type: 'tag',
          tagId: '664f384c83390ceeafa41f6a',
          i18n: {
            fr: {
              title: 'Allo Diffuz',
            },
          },
          heading: {
            color: '#FCDD2B',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: false,
          },
        },
        {
          type: 'tag',
          tagId: '5f4f6d760fc1bc58476fb233',
          i18n: {
            fr: {
              title: 'Émissions des dirigeants',
            },
          },
          heading: {
            color: '#051D54',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
        },
        {
          type: 'tag',
          tagId: '61e81da6403f657891820598',
          i18n: {
            fr: {
              title: "Voix Macif, c'est vous",
              description: `Challenge interne`,
            },
          },
          heading: {
            color: '#00C9F6',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
        },
        {
          type: 'tag',
          tagId: '60ffd6714891b549ed2f7c36',
          i18n: {
            fr: {
              title: 'Ma Préférence',
            },
          },
          heading: {
            color: '#FB9AA3',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
        },
        {
          type: 'tag',
          tagId: '603fb1c8173d8c17338e7ab6',
          i18n: {
            fr: {
              title: 'Chaîne de l’engagement',
            },
          },
          heading: {
            color: '#FF866E',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
        },
        {
          type: 'tag',
          tagId: '5fc6646f3af637511cf7704f',
          i18n: {
            fr: {
              title: 'M le Mag',
            },
          },
          heading: {
            color: '#52DFCE',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
        },
        {
          type: 'tag',
          tagId: '5f4f6d610fc1bc43c36fb231',
          i18n: {
            fr: {
              title: 'Destinations solidaires',
            },
          },
          heading: {
            color: '#cbdb16',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: false,
          },
        },
        {
          type: 'tag',
          tagId: '5f4f6d6e0fc1bca9296fb232',
          i18n: {
            fr: {
              title: '60 ans',
            },
          },
          heading: {
            color: '#051D54',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: false,
          },
        },
      ],
    },
  ],
  authMode: ['providers'],
  authOAuthProviders: [
    [
      'WSSOKeycloak',
      {
        realm: 'Macif',
        clientId: 'macif-saml',
        buttonText: 'Connexion Macif',
      },
    ],
  ],
}
