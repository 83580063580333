/**
 * configuration applied when BROCOLI_API_MODE = production
 */
module.exports = {
  authMode: ['providers'],
  authOAuthProviders: [
    [
      'WSSOKeycloak',
      {
        realm: 'Macif',
        clientId: 'macif-saml',
        buttonText: 'Connexion Macif',
      },
    ],
  ],
}
